.minimap-line {
    stroke: white;
  }
.minimap-line:hover {
    stroke: #0c72ef;
    stroke-width: 3%;
}
.minimap-station {
    fill: red;
}

.minimap-station:hover {
    fill: royalblue;
}

.minimap-station-selected {
    fill: yellow;
}